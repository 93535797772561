import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ENDPOINTS } from '../common/endpoints';

export const baseAPI = createApi({
    reducerPath: 'baseAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: ENDPOINTS.BASE_API_URL,
        credentials: 'include',
    }),
    tagTypes: ['Order', 'Item', 'Auth', 'Category', 'Customer', 'Recipe', 'Recipes', 'User', 'Fillings', 'Filling', 'RecipesLight', 'RecipesForOrder'],

    endpoints: (build) => ({}),
});
