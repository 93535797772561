import { useCallback, useState, useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { IRecipe } from '../../../../models/Recipe/IRecipe';
import CreateRecipePart from './CreateRecipePart';
import { IRecipePart } from '../../../../models/Recipe/IRecipePart';
import { DTO_STATE, FIELD_NAMES, IS_DELETED, RECIPE_SIZE } from '../../../../common/dictionary';
import { useLanguage } from '../../../../context/LanguageContext';
import getTitle from '../../../../common/translations';
import { getAllItems, getFillingsLight, saveRecipePost } from '../../dataManager';
import { getCommonStyles } from '../../../../common/commonStyles';
import { getMedia } from '../../../../common/mediaQuery';
import { IRecipeFilling } from '../../../../models/Recipe/IRecipeFilling';
import RelativeFillings from '../RelativeFillings';

interface CreateRecipeProps {
    setCreateRecipe: (state: boolean) => void;
    setIsAllRecipesIsLoading: (arg: boolean) => void;
}

const CreateRecipe = ({ setCreateRecipe, setIsAllRecipesIsLoading }: CreateRecipeProps) => {
    const { language } = useLanguage();
    const { simpleBtnStyle } = getCommonStyles();
    const { isMobile } = getMedia();

    const [recipeName, setRecipeName] = useState('');
    const [recipeDescription, setRecipeDescription] = useState('');

    const [, updateState] = useState({});
    const [flag, setFlag] = useState(false);
    const forceUpdate = useCallback(() => updateState({}), []);
    const [proportions, setProportions] = useState('1.00');

    const [newRecipeInitState, setA] = useState<IRecipe>({
        id: crypto.randomUUID(),
        parts: [],
        isDeleted: IS_DELETED.ACTIVE,
        createdAt: null,
        state: DTO_STATE.DTO_NEW,
        flagSpecial: false,
        recipeName: '',
        recipeDescription: '',
        isLinear: true,
        fillings: [],
        recipeFillings: [],
        proportionInfos: [],
    });

    const [newRecipePartsInitState] = useState<IRecipePart[]>([
        {
            id: crypto.randomUUID(),
            items: [],
            recipeId: newRecipeInitState.id,
            createdAt: null,
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
            partName: '', // todo: handle partName
            partDescription: '',
        },
    ]);

    const [newRecipeParts, setNewRecipeParts] = useState<IRecipePart[]>([...newRecipePartsInitState]);
    const [newRecipe, setNewRecipe] = useState<IRecipe>(newRecipeInitState);
    newRecipe.parts = [...newRecipeParts];
    const [disabledSaveRecipeButton, setDisabledSaveRecipeButton] = useState(false);
    const [isNameAndDescriptionEmpty, setIsNameAndDescriptionEmpty] = useState(true);
    const [isPartNameEmpty, setIsPartNameEmpty] = useState(true);
    const [isNewItemNameEmpty, setIsNewItemNameEmpty] = useState(true);
    const [isNewItemCountEmpty, setIsNewItemCountEmpty] = useState(true);
    const [isButtonDisable, setIsButtonDisable] = useState(false); // todo modify isButtonDisable

    const { items, getAllItemsError, getAllItemsIsLoading, getAllItemsIsSuccess } = getAllItems();
    const { fillings, isLoadingFillings, getFillingsError, isSuccessFillings } = getFillingsLight();
    const { saveRecipe, saveRecipeError, isSaveRecipeSuccess } = saveRecipePost();
    const [partsNames, setPartsNames] = useState([]);
    const filling_key = `${newRecipe.recipeName}_${fillings?.length || 0}_${items?.length || 0}`;

    const addRecipePart = () => {
        setNewRecipeParts((prevState) => {
            return [
                ...prevState,
                {
                    id: crypto.randomUUID(),
                    partName: '',
                    partDescription: '',
                    items: [],
                    recipeId: newRecipe.id,
                    createdAt: null,
                    isDeleted: IS_DELETED.ACTIVE,
                    state: DTO_STATE.DTO_NEW,
                },
            ];
        });

        newRecipe.parts = [...newRecipeParts];
    };

    // Special recipe??? TODO:
    const handleFlag = (e: { target: { name: string; value: string } }) => {
        setNewRecipe((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value === 'on' ? true : false,
            };
        });
    };

    const handleDeletePart = (partId: number | string) => {
        const index = newRecipeParts.findIndex((part) => part.id === partId);
        newRecipeParts.splice(index, 1);
        forceUpdate();
    };

    const handleSaveRecipe = () => {
        setNewRecipe((prevState) => {
            return {
                ...prevState,
                parts: [...newRecipeParts],
            };
        });

        newRecipe.flagSpecial = flag;
        setDisabledSaveRecipeButton(true);
        saveRecipe(newRecipe).then((res) => {
            setIsAllRecipesIsLoading(true);
            if ('error' in res) setDisabledSaveRecipeButton(false);
        });
    };

    const updatePartNames = (obj: any, updatedParts: any) => {
        obj.parts = obj.parts.map((part: any) => {
            const match = updatedParts.find((item: any) => item.id === part.id);
            if (match) {
                part.partName = match.name;
            }
            return part;
        });
    };

    const handleRelativeFillings = (fillings: IRecipeFilling[]) => {
        setNewRecipe((prev) => {
            return {
                ...prev,
                recipeFillings: [...fillings],
                state: DTO_STATE.DTO_NEW,
            };
        });
    };

    updatePartNames(newRecipe, partsNames);

    useEffect(() => {
        if (isSaveRecipeSuccess) {
            setDisabledSaveRecipeButton(false);
            setCreateRecipe(false);
            setNewRecipeParts([...newRecipePartsInitState]);
            setNewRecipe(newRecipeInitState);
        }
    }, [isSaveRecipeSuccess, setNewRecipeParts, setNewRecipe, newRecipePartsInitState, newRecipeInitState]);

    useEffect(() => {
        if (newRecipe && newRecipe.parts && newRecipe.parts.length > 0) {
            setIsButtonDisable(disabledSaveRecipeButton || isNameAndDescriptionEmpty || isPartNameEmpty || isNewItemNameEmpty || isNewItemCountEmpty);
        }

        if (newRecipe && newRecipe.parts && newRecipe.parts.length === 0) {
            setIsButtonDisable(disabledSaveRecipeButton || isNameAndDescriptionEmpty);
        }
    }, [disabledSaveRecipeButton, isNameAndDescriptionEmpty, isPartNameEmpty, isNewItemNameEmpty, isNewItemCountEmpty, newRecipe.parts]);

    useEffect(() => {
        setNewRecipe((prev) => {
            return {
                ...prev,
                recipeName,
            };
        });
    }, [recipeName]);

    useEffect(() => {
        setNewRecipe((prev) => {
            return {
                ...prev,
                recipeDescription,
            };
        });
    }, [recipeDescription]);

    return (
        <Box sx={{ ...(disabledSaveRecipeButton && { pointerEvents: 'none' }) }}>
            {/* loader spinner */}
            {disabledSaveRecipeButton ? (
                <Box sx={{ position: 'absolute', top: '45%', left: '45%' }}>
                    <CircularProgress size={'60px'} />
                </Box>
            ) : (
                <></>
            )}

            {/* add part button */}
            <Button
                disabled={disabledSaveRecipeButton}
                variant="outlined"
                onClick={addRecipePart}
                sx={{
                    marginTop: '30px',
                    ...simpleBtnStyle,
                    margin: '10px',
                    fontWeight: '400',
                }}
            >
                {getTitle(language, 'add_part')}
            </Button>

            {/* recipe name and description  */}
            <Box sx={{ margin: '10px', display: 'flex', flexWrap: 'wrap', ...(isMobile ? { width: '300px' } : { mt: '20px' }) }}>
                <TextField
                    focused
                    required
                    id={FIELD_NAMES.NAME}
                    label={getTitle(language, 'recipe_name')}
                    placeholder={getTitle(language, 'recipe_name')}
                    value={recipeName}
                    onChange={(e) => setRecipeName(e.target.value)}
                    color={recipeName === '' ? 'error' : 'success'}
                    sx={{ ...(isMobile ? { width: '100%' } : { mr: '30px' }) }}
                />

                <TextField
                    focused
                    id={FIELD_NAMES.DESCRIPTION}
                    label={getTitle(language, 'description')}
                    placeholder={getTitle(language, 'description')}
                    multiline
                    maxRows={4}
                    value={recipeDescription}
                    onChange={(e) => setRecipeDescription(e.target.value)}
                    sx={{ ...(isMobile ? { mt: '10px', width: '100%' } : { mr: '30px' }) }}
                />

                {/* TODO: Special recipe */}
                {/* <FormGroup>
                    <FormControlLabel control={<Checkbox name="flagSpecial" checked={flag} onChange={() => setFlag(!flag)} />} label="Special recipe" />
                </FormGroup>*/}

                <FormControl
                    variant="outlined"
                    size="medium"
                    disabled
                    sx={{
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px',
                            borderColor: 'rgb(46, 125, 50)',
                        },
                        width: '150px',
                        ...(isMobile ? { mt: '10px', width: '100%' } : {}),
                    }}
                >
                    <InputLabel id="recipe_size_label" sx={{ color: 'rgb(46, 125, 50)' }}>
                        {getTitle(language, 'recipe_proportion')}
                    </InputLabel>
                    <Select
                        labelId="recipe_size_label"
                        id="PROPORTIONS"
                        name="PROPORTIONS"
                        value={proportions}
                        onChange={(e) => {
                            setProportions(e.target.value);
                        }}
                        label={getTitle(language, 'recipe_proportion')}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderWidth: '2px',
                                },
                                '&:hover fieldset': {
                                    borderWidth: '2px',
                                },
                                '&.Mui-focused fieldset': {
                                    borderWidth: '2px',
                                },
                            },
                        }}
                    >
                        {RECIPE_SIZE.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Tooltip
                    placement="top"
                    title={
                        <div>
                            {getTitle(language, 'default_proportion_1')}
                            <br />
                            {getTitle(language, 'default_proportion_2')}
                        </div>
                    }
                >
                    <Box
                        sx={{
                            color: 'rgb(25, 118, 210)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            ml: '25px',
                        }}
                    >
                        <InfoIcon
                            sx={{
                                fontSize: '175%',
                                transition: 'font-size 1s',

                                ':hover': {
                                    fontSize: '275%',
                                },
                            }}
                        />
                    </Box>
                </Tooltip>
            </Box>

            {/* recipe parts */}
            <Box>
                {newRecipe.parts?.map((part) => (
                    <CreateRecipePart
                        key={part.id}
                        part={part}
                        reRender={forceUpdate}
                        items={items}
                        handleDeletePart={handleDeletePart}
                        setIsPartNameEmpty={setIsPartNameEmpty}
                        setIsNewItemNameEmpty={setIsNewItemNameEmpty}
                        setIsNewItemCountEmpty={setIsNewItemCountEmpty}
                        proportions={proportions}
                        partsNames={partsNames}
                        setPartsNames={setPartsNames}
                    />
                ))}
            </Box>
            <Box>
                <RelativeFillings
                    key={filling_key}
                    fillings={fillings}
                    setFillings={handleRelativeFillings}
                    recipeId={newRecipe.id}
                    recipeName={newRecipe.recipeName}
                    selectedFillings={newRecipe.recipeFillings}
                />
            </Box>

            {/* buttons */}
            <Box>
                <Button
                    variant="outlined"
                    // disabled={isButtonDisable} // FIXME:
                    onClick={handleSaveRecipe}
                    sx={{ ...simpleBtnStyle, margin: '10px', ...(isMobile ? { marginTop: '20px' } : {}) }}
                    endIcon={<SendIcon />}
                >
                    {getTitle(language, 'save_recipe')}
                </Button>
            </Box>
        </Box>
    );
};

export default CreateRecipe;
