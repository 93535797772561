import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopy from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import { customerAPI } from '../../../services/CustomerServices';
import { DTO_STATE, FIELDS, IS_DELETED, ORDER_SOURCES, ORDER_STAGES } from '../../../common/dictionary';
import { getCommonStyles } from '../../../common/commonStyles';
import { getDateTime, getMessageWithOrderNumber } from '../../../common/helpers';
import { ICustomer } from '../../../models/ICustomer';
import { IOrder } from '../../../models/Order/IOrder';
import { IPrepayment } from '../../../models/Order/IPrepayment';
import { ISearchString } from '../../../models/ISearchString';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { orderAPI } from '../../../services/OrderServices';
import { recipeAPI } from '../../../services/RecipeServices';
import { useLanguage } from '../../../context/LanguageContext';
import CreateCustomer from '../../customers/CreateCustomer';
import CreateSubOrder from './CreateSubOrder';
import getTitle from '../../../common/translations';
import MySnackBar from '../../common/snackBars/MySnackBar';
import { OrderValidator } from '../../../validators/OrderValidator';
import { imageAPI } from '../../../services/ImageService';

interface CreateOrderProps {
    handleExistOrderId: (ids: string[]) => void;
    handleCreateOrderMode: () => void;
}

interface IError {
    status: number;
    data: string[];
}

interface IConnectionError {
    status: string;
    error: string;
}

const CreateOrder = ({ handleExistOrderId, handleCreateOrderMode }: CreateOrderProps) => {
    const { language } = useLanguage();
    const { simpleBtnStyle } = getCommonStyles();

    const { data: recipes, error: getRecipesError, isLoading: getRecipesIsLoading, isSuccess: getRecipesIsSuccess } = recipeAPI.useGetRecipesForOrderQuery();
    const [searchCustomers, { error: searchError, isSuccess: searchIsSuccess }] = customerAPI.useSearchCustomersMutation();
    const [saveOrder, { error: saveOrderError, isSuccess: saveOrderIsSuccess }] = orderAPI.useSaveOrderMutation();
    const [expandCreateCustomer, setExpandCreateCustomer] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
    const [infoExistOrderSnackBar, setInfoExistOrderSnackBar] = useState<boolean>(false);
    const [saveOrderSuccessSnackBar, setSaveOrderSuccessSnackBar] = useState<boolean>(false);
    const [saveOrderErrorSnackBar, setSaveOrderErrorSnackBar] = useState<boolean>(false);
    const [copyOrderNumberSnackBar, setCopyOrderNumberSnackBar] = useState<boolean>(false);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
    const [inValidOrder, setInValidOrder] = useState(false);
    const [profit, setProfit] = useState('');

    const [prepaymentInitialState] = useState<IPrepayment>({
        id: -1,
        prepayment: 0,
        state: DTO_STATE.DTO_NEW,
    });
    useParams;
    const [newOrderInitialState] = useState<IOrder>({
        id: crypto.randomUUID(),
        orderNumber: '',
        description: '',
        isDeleted: IS_DELETED.ACTIVE,
        state: DTO_STATE.DTO_NEW,
        price: 0,
        cost: 0,
        customerId: 0,
        subOrders: [],
        stage: ORDER_STAGES.NEW,
        pickUpAt: '',
    });
    const [subOrderInitialState] = useState<ISubOrder[]>([
        {
            id: crypto.randomUUID(),
            recipeName: '',
            recipeId: 0,
            description: '',
            price: 0,
            productCost: 0,
            count: 1,
            recipeCounter: 0,
            orderId: newOrderInitialState.id,
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
            fillingId: 0,
            images: [],
        },
    ]);
    const [searchResponse, setSearchResponse] = useState<ICustomer[]>();
    let customersField: string[] = [];
    const [subOrders, setSubOrders] = useState<ISubOrder[]>([...subOrderInitialState]);
    const [newOrder, setNewOrder] = useState<IOrder>({ ...newOrderInitialState });
    newOrder.prepayment = { ...prepaymentInitialState };
    newOrder.subOrders = [...subOrders];
    const [orderDescription, setOrderDescription] = useState<string>('');
    const [searchPhrase, setSearchPhrase] = useState<string>('');
    let searchAfterAddNewCustomer = '';
    const validator = useMemo(() => new OrderValidator(newOrder, recipes || []), [newOrder, recipes]);

    const search = () => {
        let timeout: string | number | NodeJS.Timeout | undefined;
        return (e: { target: { value: string } } | null) => {
            const localSearchPhrase: string = e === null ? searchAfterAddNewCustomer : e.target.value;
            searchAfterAddNewCustomer = localSearchPhrase;
            setSearchPhrase(localSearchPhrase);
            const searchString: ISearchString = { search: localSearchPhrase };
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                setSearchResponse(undefined);
                localSearchPhrase.length >= 3 &&
                    searchCustomers(searchString).then((result) => {
                        if ('data' in result) {
                            setSearchResponse(result.data);
                            handelDialogOpen();
                        }
                    });
            }, 1000);
        };
    };

    const debounceSearch = useMemo(() => search(), []);
    const processSearchResponse = () => {
        const exInstagram = new RegExp('.*instagram.*');
        const exFacebook = new RegExp('.*facebook.*');
        const exMail = new RegExp('^[\\w\\d\\.]+@[\\w\\.]+$');
        const exPhoneNumber = new RegExp('^\\+?\\d+$');
        if (exInstagram.test(searchPhrase)) {
            searchResponse?.map((c) => customersField.push(c.instagram || ''));
            return;
        }
        if (exFacebook.test(searchPhrase)) {
            searchResponse?.map((c) => customersField.push(c.faceBook || ''));
            return;
        }
        if (exMail.test(searchPhrase)) {
            searchResponse?.map((c) => customersField.push(c.email || ''));
            return;
        }
        if (exPhoneNumber.test(searchPhrase)) {
            searchResponse?.map((c) => customersField.push(c.phoneNumber || ''));
            return;
        }
        searchResponse?.map((c) => customersField.push(c.name || ''));
    };

    useMemo(() => processSearchResponse(), [searchResponse]);

    const handelCreateCustomer = () => {
        setExpandCreateCustomer(!expandCreateCustomer);
    };

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handelDialogOpen = () => {
        setDialogOpen(!dialogOpen);
    };

    const handleCustomerId = (e: any, customer: ICustomer) => {
        newOrder.customerId = customer.id;
        setSelectedCustomer(customer);
        handelDialogOpen();
    };
    const [selectCustomerDialog, setSelectCustomerDialog] = useState<boolean>(false);
    const handelSelectCustomerDialog = () => {
        setSelectCustomerDialog(!selectCustomerDialog);
    };

    const handleOrderCosts = () => {
        let cost = 0;
        let price = 0;
        subOrders.forEach((element) => {
            cost += element.productCost;
            price += element.price * element.count;
        });

        setNewOrder((prevState) => {
            return {
                ...prevState,
                cost: cost,
                price: price,
            };
        });
    };
    const [pickUpDate, setPickUpDate] = useState('');
    const handlePickUpDateTime = (e: any) => {
        const exDateTime = new RegExp('(?<year>\\d{4})-(?<month>\\d{1,2})-(?<day>\\d{1,2})T(?<hour>\\d{1,2}):(?<min>\\d{1,2})');
        if (exDateTime.test(e.target.value)) {
            setPickUpDate(e.target.value);
            setNewOrder((prev) => {
                return {
                    ...prev,
                    pickUpAt: e.target.value,
                };
            });
        }
    };

    const handleDescription = (e: any) => {
        setOrderDescription(e.target.value);
        setNewOrder((prev) => {
            return {
                ...prev,
                description: e.target.value,
            };
        });
    };

    const handleAddSubOrder = () => {
        setSubOrders((prevState) => {
            return [
                ...prevState,
                {
                    id: crypto.randomUUID(),
                    recipeName: '',
                    recipeId: 0,
                    description: '',
                    price: 0,
                    productCost: 0,
                    count: 1,
                    recipeCounter: 0,
                    orderId: newOrderInitialState.id,
                    isDeleted: IS_DELETED.ACTIVE,
                    state: DTO_STATE.DTO_NEW,
                    fillingId: 0,
                    images: [],
                },
            ];
        });
        newOrder.subOrders = [...subOrders];
    };

    const [newOrderNumber, setNewOrderNumber] = useState('');
    const [existOrderId, setExistOrderId] = useState<string[]>();
    const [saveImages, { error: saveImagesError, isSuccess: saveImagesIsSuccess }] = imageAPI.useSaveImagesMutation();
    const [files, setFiles] = useState<Map<string, File>>(new Map());

    const handleSaveNewOrder = async () => {
        setDisableSaveButton(true);
        const formData = new FormData();
        files.forEach((el) => {
            formData.append('image', el);
        });
        saveImages(formData);
        saveOrder(newOrder).then((res) => {
            if ('error' in res) {
                const err = res.error as IConnectionError;
                if (err.status === 'FETCH_ERROR') {
                    setSaveOrderErrorSnackBar(true);
                    setDisableSaveButton(false);
                }
            }
            if ('error' in res) {
                const err = res.error as IError;
                if (err.status === 400) setExistOrderId(err.data);
            }
            if ('data' in res) res.data.orderNumber && setNewOrderNumber(res.data.orderNumber);
        });
    };

    const handleUpdateSubOrders = (subOrder: ISubOrder) => {
        const newSubOrders: ISubOrder[] = [];
        subOrders?.forEach((el) => {
            if (el.id !== subOrder.id) newSubOrders.push(el);
            else newSubOrders.push(subOrder);
        });
        setSubOrders([...newSubOrders]);
    };

    const handleDeleteSubOrder = (id: string | number) => {
        const subOrder = subOrders.find((el) => el.id === id);
        if (subOrder?.images && subOrder?.images.length > 0) {
            const newMap = new Map(files);
            subOrder.images.forEach((el) => newMap.delete(el.fileName));
            setFiles(newMap);
        }

        const newSubOrders: ISubOrder[] = [];
        subOrders?.forEach((el) => {
            if (el.id !== id) newSubOrders.push(el);
        });

        setSubOrders([...newSubOrders]);
    };

    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };

    const copyOrderNumber = (lang?: string) => {
        window.navigator.clipboard.writeText(getMessageWithOrderNumber(newOrderNumber, lang));
        setCopyOrderNumberSnackBar(true);
    };

    const setFile = (f: File[]) => {
        const filteredFiles = f.filter((file) => {
            if (!files.has(file.name)) {
                return true;
            }
            return false;
        });
        const newMap = new Map(files);

        filteredFiles.forEach((file) => {
            newMap.set(file.name, file);
        });

        setFiles(newMap);
    };

    const removeFile = (fileName: string) => {
        setFiles((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.delete(fileName);
            return newMap;
        });
    };

    useEffect(() => {
        if (saveOrderError && existOrderId) setInfoExistOrderSnackBar(true);
        if (saveOrderError && !existOrderId) setSaveOrderErrorSnackBar(true);
        if (saveOrderIsSuccess && !saveOrderError) setSaveOrderSuccessSnackBar(true);
    }, [saveOrderError, existOrderId, saveOrderIsSuccess]);

    useEffect(() => {
        const isValidOrder = validator.validate();
        setInValidOrder(!isValidOrder);
    }, [newOrder, pickUpDate, selectedCustomer]);

    useEffect(() => {
        handleOrderCosts();
    }, [subOrders]);

    useEffect(() => {
        setProfit(Number(newOrder.price - newOrder.cost).toFixed(2));
    }, [newOrder.price, newOrder.cost]);

    return (
        <Box style={{ margin: 10 }}>
            <Box sx={{ paddingBottom: '10px' }}>
                <Stack direction="row" divider={<Divider flexItem />} alignItems="center" justifyContent={'space-evenly'} gap={'5px'}>
                    <Button variant="outlined" onClick={handleCreateOrderMode} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                        {getTitle(language, FIELDS.ORDERS.BACK_TO_ORDERS)}
                    </Button>
                </Stack>
            </Box>
            {saveOrderIsSuccess ? (
                <>
                    <MySnackBar
                        duration={5000}
                        openSnackBar={saveOrderSuccessSnackBar}
                        handleClose={() => setSaveOrderSuccessSnackBar(false)}
                        severity={'success'}
                        alertText="Save order success!"
                    />
                    <Box>
                        {/* Template for Natali. Need to save templates in DB user settings table.
                    me */}
                        UKR
                        <IconButton onClick={() => copyOrderNumber('ukr')}>
                            <ContentCopy />
                        </IconButton>
                    </Box>
                    <Box>
                        {/* Template for Natali. Need to save templates in DB user settings table.
                me */}
                        RUS
                        <IconButton onClick={() => copyOrderNumber('rus')}>
                            <ContentCopy />
                        </IconButton>
                    </Box>
                    <Box>
                        {/* Template for Natali. Need to save templates in DB user settings table.
                me */}
                        PL
                        <IconButton onClick={() => copyOrderNumber('pl')}>
                            <ContentCopy />
                        </IconButton>
                    </Box>
                    <Box>
                        {/* Template for Natali. Need to save templates in DB user settings table.
            me */}
                        Номер
                        {/* {getMessageWithOrderNumber(newOrderNumber)} */}
                        {/* {getTitle(language, 'order_number')} {newOrderNumber} */}
                        <IconButton onClick={() => copyOrderNumber()}>
                            <ContentCopy />
                        </IconButton>
                    </Box>
                </>
            ) : saveOrderError && existOrderId ? (
                <Box sx={{ mt: '20px' }}>
                    <MySnackBar
                        duration={5000}
                        openSnackBar={infoExistOrderSnackBar}
                        handleClose={() => setInfoExistOrderSnackBar(false)}
                        severity={'info'}
                        alertText="Order exist!"
                    />
                    {getTitle(language, FIELDS.ORDERS.AN_ORDER_FOR_THE_CUSTOMER)}
                    &nbsp;
                    {selectedCustomer?.name}
                    &nbsp;
                    {getTitle(language, 'at')} {getDateTime(pickUpDate)?.date}
                    &nbsp;
                    {getTitle(language, FIELDS.COMMON.ALREADY_EXISTS)}.
                    <Box sx={{ mt: '20px' }}>
                        <Button variant="outlined" onClick={() => handleExistOrderId(existOrderId)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                            {getTitle(language, FIELDS.ORDERS.SEARCHED_ORDER)}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <Stack spacing={2} sx={{ minWidth: 100, maxWidth: 400, mb: '10px' }}>
                        <TextField
                            style={{ marginBottom: 10 }}
                            id={'description'}
                            label={getTitle(language, FIELDS.ORDERS.ORDER_DESCRIPTION)}
                            placeholder={getTitle(language, FIELDS.ORDERS.ORDER_DESCRIPTION)}
                            value={orderDescription}
                            focused
                            multiline
                            onChange={handleDescription}
                            color={orderDescription === '' ? 'primary' : 'success'}
                        />
                        <TextField
                            style={{ marginBottom: 10 }}
                            required
                            id={'searchPhrase'}
                            label={getTitle(language, FIELDS.ORDERS.SEARCH_CUSTOMER)}
                            placeholder={getTitle(language, FIELDS.ORDERS.SEARCH_CUSTOMER)}
                            value={searchPhrase}
                            onChange={debounceSearch}
                            focused
                            error={searchPhrase.length < 3}
                        />
                        <Dialog fullWidth open={dialogOpen} onClose={handelDialogOpen}>
                            <DialogTitle>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>{getTitle(language, FIELDS.ORDERS.CUSTOMERS)}</Box>
                                    <Box>
                                        <Button variant="outlined" onClick={handelDialogOpen} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                            {getTitle(language, 'close')}
                                        </Button>
                                    </Box>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>{!searchIsSuccess && !searchResponse && <CircularProgress />}</Box>

                                {searchResponse &&
                                    searchResponse.map((c) => (
                                        <CustomerInDialog key={c.id} customer={c} handleCustomerId={handleCustomerId} selectedCustomer={newOrder.customerId === c.id} />
                                    ))}
                            </DialogContent>
                            <DialogActions></DialogActions>
                        </Dialog>

                        {searchError && (
                            <>
                                <Box>{getTitle(language, FIELDS.ORDERS.CUSTOMER_NOT_FOUND)}</Box>

                                <Button variant="outlined" onClick={handelCreateCustomer} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                    {getTitle(language, FIELDS.ORDERS.CREATE_CUSTOMER)}
                                </Button>

                                {expandCreateCustomer && <CreateCustomer search={debounceSearch} handleCreateCustomerDialog={handelCreateCustomer} />}
                            </>
                        )}

                        {searchResponse && (
                            <Button variant="outlined" onClick={handelDialogOpen} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                {getTitle(language, FIELDS.ORDERS.FOUND_CUSTOMERS)}
                            </Button>
                        )}

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ mr: '10px', ml: '10px' }}>{getTitle(language, FIELDS.ORDERS.SELECTED_CUSTOMER)}</Box>

                            {selectedCustomer?.name === undefined ? (
                                <Box sx={{ backgroundColor: '#f49d9d75', padding: '3px', borderRadius: '3px', pr: '6px', pl: '6px' }}>
                                    {getTitle(language, FIELDS.ORDERS.NO_CUSTOMER)}
                                </Box>
                            ) : (
                                <Box>{selectedCustomer?.name}</Box>
                            )}
                        </Box>
                        <Box>
                            {selectedCustomer?.name !== undefined ? (
                                <Button variant="outlined" onClick={handelSelectCustomerDialog} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                    {getTitle(language, FIELDS.ORDERS.CUSTOMER_INFO)}
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Stack>
                    <Box>
                        <Button variant="outlined" onClick={handleAddSubOrder} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                            {getTitle(language, FIELDS.ORDERS.ADD_SUBORDER)}
                        </Button>
                    </Box>
                    {subOrders.map((s) => (
                        <CreateSubOrder
                            key={s.id}
                            setFile={setFile}
                            removeFile={removeFile}
                            subOrder={s}
                            recipes={recipes}
                            handleUpdateSubOrders={handleUpdateSubOrders}
                            deleteSubOrder={handleDeleteSubOrder}
                        />
                    ))}
                    <Box>
                        {getTitle(language, FIELDS.ORDERS.TOTAL_ORDER_PRICE)}
                        {Number(newOrder.price).toFixed(2)} zł
                    </Box>
                    <Box>
                        {getTitle(language, FIELDS.ORDERS.TOTAL_ORDER_COST)}
                        {Number(newOrder.cost).toFixed(2)} zł
                    </Box>
                    <Box>
                        {getTitle(language, FIELDS.ORDERS.TOTAL_ORDER_PROFIT)}
                        {profit} zł
                    </Box>
                    <Box style={{ paddingTop: 10 }}>
                        <TextField
                            style={{ marginBottom: 10 }}
                            required
                            id={'pickUpDate'}
                            label={getTitle(language, FIELDS.ORDERS.PICK_UP_DATE)}
                            type="datetime-local"
                            placeholder={getTitle(language, FIELDS.ORDERS.PICK_UP_DATE)}
                            onChange={handlePickUpDateTime}
                            onClick={handlePickUpDateTime}
                            color={!pickUpDate ? 'error' : 'success'}
                            focused
                        />
                    </Box>
                    <Box>
                        <Button
                            disabled={inValidOrder || disableSaveButton}
                            variant="outlined"
                            onClick={handleSaveNewOrder}
                            sx={{ ...buttonStyle, ...simpleBtnStyle, mt: '20px' }}
                            endIcon={<SendIcon />}
                        >
                            {getTitle(language, FIELDS.ORDERS.SAVE_ORDER)}
                        </Button>
                    </Box>
                    {selectedCustomer && (
                        <Dialog fullWidth open={selectCustomerDialog} onClose={handelSelectCustomerDialog}>
                            <DialogTitle>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box> {getTitle(language, FIELDS.ORDERS.SELECTED_CUSTOMER)}</Box>
                                    <Box>
                                        <Button variant="outlined" onClick={handelSelectCustomerDialog} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                            {'X'}
                                        </Button>
                                    </Box>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <CustomerInDialog
                                    key={selectedCustomer.id}
                                    customer={selectedCustomer}
                                    handleCustomerId={handleCustomerId}
                                    selectedCustomer={newOrder.customerId === selectedCustomer.id}
                                />
                            </DialogContent>
                            <DialogActions></DialogActions>
                        </Dialog>
                    )}
                </Box>
            )}
            <MySnackBar
                duration={5000}
                openSnackBar={saveOrderErrorSnackBar}
                handleClose={() => setSaveOrderErrorSnackBar(false)}
                severity={'error'}
                alertText="Save order error!"
            />
            <MySnackBar
                duration={5000}
                openSnackBar={copyOrderNumberSnackBar}
                handleClose={() => setCopyOrderNumberSnackBar(false)}
                severity={'success'}
                alertText="The order number is copied!"
            />
        </Box>
    );
};

export default CreateOrder;

interface CustomerInDialogProps {
    customer: ICustomer;
    handleCustomerId: (e: any, customer: ICustomer) => void;
    selectedCustomer: boolean;
}

const CustomerInDialog = ({ customer, handleCustomerId, selectedCustomer }: CustomerInDialogProps) => {
    const { language } = useLanguage();
    let color = customer.id % 2 === 0 ? 'rgba(245, 245, 220, 0.7)' : 'rgba(211, 211, 211, 0.5)';
    color = selectedCustomer ? 'rgba(50, 205, 50, 0.3)' : color;
    const handleSelectCustomer = (e: any) => {
        handleCustomerId(e, customer);
    };
    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const { simpleBtnStyle } = getCommonStyles();

    return (
        <Box sx={{ backgroundColor: color, padding: '10px' }}>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.FULL_NAME)}
                {customer.name}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.EMAIL)}
                {customer.email}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.PHONE_NUMBER)}
                {customer.phoneNumber}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.INSTAGRAM)}
                {customer.instagram}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, FIELDS.ORDERS.FACEBOOK)}
                {customer.faceBook}
            </Box>
            {!selectedCustomer && (
                <Box>
                    <Button variant="outlined" onClick={handleSelectCustomer} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                        {getTitle(language, FIELDS.ORDERS.SELECT)}
                    </Button>
                </Box>
            )}
        </Box>
    );
};
